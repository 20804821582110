.cyberfox {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  color: orange;
  text-decoration: none;
}

.fox {
  margin: 0% auto;
}

.white {
  color: white;
}

.grass {
  color: lime;
}

.lapk {
  color: gray;
}
