.about_wrapper {
  margin: 0;
  padding-top: 20px;
}
.about {
  color: orange;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
  width: 50%;
  padding: 8px 30px;
  margin: 30px auto;
  border-radius: 10px;
  top: 0;
}

.about h1 {
  text-align: center;
  color: orangered;
}

.about a {
  color: dodgerblue;
  text-decoration: none;
  border-bottom: 1px solid dodgerblue;
}

.navLine_link {
  display: none;
  color: dodgerblue;
  padding-left: 20px;
  font-size: 20px;
  text-decoration: none;
}

@media (max-width: 1250px) {
  .about {
    width: 70%;
  }
}

@media (orientation: portrait) {
  .about {
    width: 88%;
    padding: 4px 20px;
  }
  .navLine_link {
    display: flex;
  }
}
