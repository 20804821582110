.name {
  color: #f16409;
  margin: 18vh 0 3vh 0;
  font-size: 13vh;
  text-align: center;
}

@media (orientation: portrait) {
  .name {
    margin: 5vh 2% 3vh;
    font-size: 10vw;
  }
}

@media (max-width: 1250px) {
  .name {
    font-size: 8vh;
  }
}
