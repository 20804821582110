.gmnav_list {
  background: #3c3c3c;
  font-size: 0;
  padding: 0;
}

.gmnav_list li {
  display: inline-block;
  font-size: 1rem;
  padding: 3px 0 6px;
}

.gmnav_list li a {
  font-weight: bold;
  text-decoration: none;
  color: lime;
  padding: 3px 10px;
  font-size: 13px;
}
.gmnav_list li:hover {
  background: #696969;
}

.delimeter {
  color: gray;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
}
