.navWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.nav {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  -webkit-backdrop-filter: blur(7px);
  z-index: 1; /* Fix for Safari */
}

@media (orientation: portrait) {
  .navWrapper {
    display: none;
  }
}
