.linkButton {
  display: flex;
  align-items: center;
  height: 47px;
  font-size: 20px;
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 32px;
  background-color: rgba(17, 17, 17, 0.53);
  backdrop-filter: blur(7px);
  transition: all 375ms ease;
  white-space: nowrap;
  z-index: 1;
}

.linkButton:hover {
  background-color: rgba(12, 12, 12, 0.85);
  color: #00a6ff;
  transition: all 225ms ease;
}

@media (max-width: 1600px) {
  .linkButton {
    height: 40px;
    padding: 15px 25px;
  }
}

@media (max-width: 1450px) {
  .linkButton {
    height: 35px;
    font-size: 18px;
  }
}

@media (max-width: 1350px) {
  .linkButton {
    height: 30px;
    font-size: 15px;
  }
}

@media (max-width: 1200px) {
  .linkButton {
    height: 25px;
    padding: 15px 20px;
    font-size: 13px;
  }
}

@media (max-width: 1050px) {
  .linkButton {
    height: 24px;
    padding: 14px 18px;
    font-size: 12px;
  }
}
