.arrows {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2vh;
}

.arrow-right {
  position: relative;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid #8a8a8a;
  border-bottom: 10px solid transparent;
  animation: blink 1.5s infinite;
}

.arrow-right:before {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid #FFFFFF99;
  border-bottom: 10px solid transparent;
  animation: blink 1.5s infinite alternate;
}

.arrow-left {
  position: relative;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 15px solid #8a8a8a;
  border-bottom: 10px solid transparent;
  animation: blink 1.5s infinite;
}

.arrow-left:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 15px solid #FFFFFF99;
  border-bottom: 10px solid transparent;
  animation: blink 1.5s infinite alternate;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media screen and (orientation: landscape) {
  .arrows {
    display: none;
  }
}
