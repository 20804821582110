.social_bar_container {
  display: flex;
  justify-content: center;
}

.social_bar {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(4px);
  border-radius: 7px;
  overflow: hidden;
  z-index: 2;
}

@media screen and (orientation: portrait) {
  .social_bar {
    margin: 20% 5%;
  }
}
