.player {
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  width: 500px;
}

.play {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.34);
}

.play svg {
  color: dodgerblue;
  width: 80%;
  height: auto;
  margin: 0 auto;
  transition: filter 0.3s ease-in-out;
}
.play svg:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 10px dodgerblue);
}

